import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'lib-flexible';
import axios from "axios";
import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant)
// H5引用
Vue.prototype.$axios = axios; //this.$axios使用
Vue.prototype.$url = 'https://alqtest.69dp.cn'; //this.$axios测试使用
// 统计图插件
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const wx = window.wx; // index.html中引入外部js，获取js暴露的wx
Vue.$wx = Vue.prototype.$wx = wx;


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
