import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: "首页" },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/Statistics",
    name: "Statistics",
    meta: { title: "统计" },
    component: () => import("../views/Statistics.vue"),
  },
  {
    path: "/MySummary",
    name: "MySummary",
    meta: { title: "我的小结" },
    component: () => import("../views/MySummary.vue"),
  },
  {
    path: "/Customer",
    name: "Customer",
    meta: { title: "客户" },
    component: () => import("../views/Customer.vue"),
  },
  {
    path: "/CustomerDynamics",
    name: "CustomerDynamics",
    meta: { title: "客户动态" },
    component: () => import("../views/CustomerDynamics.vue"),
  },
  {
    path: "/CreateFollow",
    name: "CreateFollow",
    meta: { title: "添加跟进" },
    component: () => import("../views/CreateFollow.vue"),
  },
  {
    path: "/Info",
    name: "Info",
    meta: { title: "个人信息" },
    component: () => import("../views/Info.vue"),
  },
  {
    path: "/Label",
    name: "Label",
    meta: { title: "客户" },
    component: () => import("../views/Label.vue"),
  },
  {
    path: "/Material",
    name: "Material",
    meta: { title: "素材" },
    component: () => import("../views/Material.vue"),
  },
  {
    path: "/Script",
    name: "Script",
    meta: { title: "话术" },
    component: () => import("../views/Script.vue"),
  },
  {
    path: "/Recommend",
    name: "Recommend",
    meta: { title: "推荐" },
    component: () => import("../views/Recommend.vue"),
  },
  {
    path: "/Task",
    name: "Task",
    meta: { title: "待办任务列表" },
    component: () => import("../views/Task.vue"),
  },
  {
    path: "/Application",
    name: "Application",
    meta: { title: "应用" },
    component: () => import("../views/Application.vue"),
  },
  {
    path: "/Mine",
    name: "Mine",
    meta: { title: "我的" },
    component: () => import("../views/Mine.vue"),
  },
  {
    path: "/CreateTask",
    name: "CreateTask",
    meta: { title: "配置任务" },
    component: () => import("../views/CreateTask.vue"),
  },
  {
    path: "/CreateRemind",
    name: "CreateRemind",
    meta: { title: "添加提醒" },
    component: () => import("../views/CreateRemind.vue"),
  },
  {
    path: "/CreateTaskRules",
    name: "CreateTaskRules",
    meta: { title: "配置任务" },
    component: () => import("../views/CreateTaskRules.vue"),
  },
  {
    path: "/LiveCode",
    name: "LiveCode",
    meta: { title: "群活码" },
    component: () => import("../views/LiveCode.vue"),
  },
  {
    path: "/CreateCode",
    name: "CreateCode",
    meta: { title: "新建群活码" },
    component: () => import("../views/CreateCode.vue"),
  },
  {
    path: "/MyCode",
    name: "MyCode",
    meta: { title: "我的企微二维码" },
    component: () => import("../views/MyCode.vue"),
  },
  {
    path: "/MarketingActivities",
    name: "MarketingActivities",
    meta: { title: "营销活动" },
    component: () => import("../views/MarketingActivities.vue"),
  },
  {
    path: "/CouponDetails",
    name: "CouponDetails",
    meta: { title: "优惠券" },
    component: () => import("../views/CouponDetails.vue"),
  },
  {
    path: "/ReceivingRecord",
    name: "ReceivingRecord",
    meta: { title: "领取记录" },
    component: () => import("../views/ReceivingRecord.vue"),
  },
  {
    path: "/SignIn",
    name: "SignIn",
    meta: { title: "营销活动" },
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/TaskDetail",
    name: "TaskDetail",
    meta: { title: "任务详情" },
    component: () => import("../views/TaskDetail.vue"),
  },
  {
    path: "/UserInfo",
    name: "UserInfo",
    meta: { title: "个人详情" },
    component: () => import("../views/UserInfo.vue"),
  },
  {
    path: "/AddScript",
    name: "AddScript",
    meta: { title: "添加话术" },
    component: () => import("../views/AddScript.vue"),
  },
  {
    path: "/AddMaterial",
    name: "AddMaterial",
    meta: { title: "添加素材" },
    component: () => import("../views/AddMaterial.vue"),
  },
  // 内嵌H5 
  // 大转盘
  {
    path: "/H5_luckdraw_new",
    name: "H5_luckdraw_new",
    meta: { title: "大转盘" },
    component: () => import("../H5/luckdraw/luckdraw_new.vue"),
  },
  {
    path: "/H5_luckdrawdetails_new",
    name: "H5_luckdrawdetails_new",
    meta: { title: "大转盘" },
    component: () => import("../H5/luckdraw/luckdrawdetails_new.vue"),
  },
  {
    path: "/H5_luckdrawEntity_new",
    name: "H5_luckdrawEntity_new",
    meta: { title: "大转盘" },
    component: () => import("../H5/luckdraw/luckdrawEntity_new.vue"),
  },
  {
    path: "/H5_luckdrawhint_new",
    name: "H5_luckdrawhint_new",
    meta: { title: "大转盘" },
    component: () => import("../H5/luckdraw/luckdrawhint_new.vue"),
  },
  {
    path: "/H5_luckdrawlist_new",
    name: "H5_luckdrawlist_new",
    meta: { title: "大转盘" },
    component: () => import("../H5/luckdraw/luckdrawlist_new.vue"),
  },
  // 游戏
  {
    path: "/H5_game",
    name: "H5_game",
    meta: { title: "大转盘" },
    component: () => import("../H5/game/index.vue"),
  },
  {
    path: "/H5_luckdraw",
    name: "H5_luckdraw",
    meta: { title: "幸运大转盘" },
    component: () => import("../H5/luckdraw/luckdraw.vue"),
  },
  {
    path: "/H5_luckdrawlist",
    name: "H5_luckdrawlist",
    meta: { title: "幸运大转盘" },
    component: () => import("../H5/luckdraw/luckdrawlist.vue"),
  },
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  next();
});

export default router;
